import { map } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { ReplaySubject } from "rxjs";
import { FeService } from "./fe.service";
declare var $: any;
@Injectable()
export class ProductService {
  productDataObservable$ = new ReplaySubject(1);

  private environments = {
    // add your local environment in this array to test on local network devices
    "https://dev-store.nvidia.com": "https://stg-api.nvidia.partners",
    "https://stg-store.nvidia.com": "https://stg-api.nvidia.partners",
    "https://store.nvidia.com": "https://api.nvidia.partners",
    "http://localhost:4200": "https://stg-api.nvidia.partners",
  };
  private feAPIs = {
    "https://dev-store.nvidia.com": "https://stg-api.store.nvidia.com",
    "https://stg-store.nvidia.com": "https://api.store.nvidia.com",
    "https://store.nvidia.com": "https://api.store.nvidia.com",
    "http://localhost:4200": "https://stg-api.store.nvidia.com",
  };
  private headerEnvironments = {
    // add your local environment in this array to test on local network devices
    "http://localhost:4200": "https://stg-store.nvidia.com",
    "https://dev-store.nvidia.com": "https://stg-store.nvidia.com",
    "https://stg-store.nvidia.com": "https://stg-store.nvidia.com",
    "https://store.nvidia.com": "https://www.nvidia.com",
  };
  private currentEnvironment;
  private currentHeaderEnvironment;
  private origin;
  private countryCode;
  private locale: any;
  private feSkuInfo;

  constructor(private http: HttpClient, private feService: FeService) {
    this.locale == window.location.origin;
    this.countryCode = window.location.pathname.split("/")[1];
    this.origin = window.location.origin;
    this.countryCode = window.location.pathname
      .split("/")[1]
      .split("-")[1]
      .toUpperCase();
    localStorage.removeItem("hasCompared");

    if (this.countryCode == "GB") {
      this.countryCode = "UK";
    }
    if (!this.environments.hasOwnProperty(this.origin)) {
      console.info(
        "Your environment is new, not in the environments object, so it can be added."
      );
      this.environments[this.origin] = this.origin;
      this.headerEnvironments[this.origin] = "https://www.nvidia.com";
    } else {
      console.info("Your environment was found in environments.");
    }
    for (var i in this.environments) {
      if (i === this.origin) {
        this.currentEnvironment = this.environments[i];
      }
    }
    this.headerEnvironments[this.origin] = this.origin;
    for (var i in this.headerEnvironments) {
      if (i === this.origin) {
        this.currentHeaderEnvironment = this.headerEnvironments[i];
      }
    }
  }
  getProduct(queryParams, forceRefresh?: boolean) {
    let nvPartnerCheckout: boolean = sessionStorage.getItem('nvPartnerCheckout') ? JSON.parse(sessionStorage.getItem('nvPartnerCheckout')) : true;
    let partnerCheckoutPids = sessionStorage.getItem('pids');
    let params = new HttpParams();
    for (let i in queryParams) {
      if (queryParams[i]) {
        params = params.append(i, queryParams[i]);
      }
    }

    let requestOptions = { params: null };
    requestOptions.params = params;

    let api_url = this.currentEnvironment + "/edge/product/search";
    if (this.currentEnvironment == "https://stg-api.nvidia.partners") {
      api_url = this.currentEnvironment + "/zwxrnyowbw/product/search";
    } else if (this.currentEnvironment == "https://dev-api.nvidia.partners") {
      api_url = this.currentEnvironment + "/zupdev/product/search";
    }

    return this.http.get(api_url, requestOptions).pipe(
      map((response: Response) => {
        const myObjStrOrg = response;
        let myObjStr = myObjStrOrg;
        let category_order = [
          "GPU",
          "LAPTOP",
          "STUDIO-LAPTOP",
          "DESKTOP",
          "STUDIO_DESKTOP",
          "MONITOR",
          "MICE",
          "GFN",
          "NVLINKS",
          "MODELS",
          "GAMING_SOFTWARE"
        ];
        let updated_category_filters = [];
        for (let co of category_order) {
          for (let _ri of myObjStr["filters"]) {
            //filterValues
            if (_ri.displayName == "category") {
              for (let catfv of _ri["filterValues"]) {
                if (catfv["dispValue"] === co) {
                  updated_category_filters.push(catfv);
                }
              }
            }
          }
        }
        for (let _ri of myObjStr["filters"]) {
          if (_ri.displayName == "category") {
            _ri["filterValues"] = updated_category_filters;
          }
        }
        if (this.countryCode == "TW") {
          let tmpSort = [];
          for (let _ri of myObjStr["sort"]) {
            if (
              _ri.displayName !== "featured_desktop" &&
              _ri.displayName !== "featured_nvlinks"
            ) {
              tmpSort.push(_ri);
            }
          }
          myObjStr["sort"] = tmpSort;
          //  featured_desktop,featured_nvlinks
        }
        //For Featured Listing Product
        if (myObjStr["searchedProducts"].featuredProduct) {
          try {
            let product = myObjStr["searchedProducts"].featuredProduct;
            product.isPDP = false;
            if (
              (product.prdStatus == "buy_now" ||
                product.prdStatus == "out_of_stock") &&
              !product.digitialRiverID
            ) {
              for (let [i, ret] of product["retailers"].entries()) {
                if (
                  ret.retailerName != null &&
                  ret.retailerName &&
                  ret.retailerName.indexOf("nvidia.com") > -1
                ) {
                  product.isPDP = true;
                  let nvRetail = product["retailers"].splice(i, 1)[0];
                  product["retailers"].splice(0, 0, nvRetail);
                }
                if (!(product['internalLink'] && product['internalLink'].length)) {
                  product['internalLink'] = ret.purchaseLink
                }
              }
            }
            if (product.category == "GFN") {
              console.log("enterered", product);
              for (let [i, ret] of product["retailers"].entries()) {
                if (
                  ret.retailerName != null &&
                  ret.retailerName &&
                  ret.retailerName.indexOf("nvidia.com") > -1
                ) {
                  product.isPDP = false;
                  ret.purchaseLink = ret.purchaseLink;
                  let nvRetail = product["retailers"].splice(i, 1)[0];
                  product["retailers"].splice(0, 0, nvRetail);
                }
                if (!(product['internalLink'] && product['internalLink'].length)) {
                  product['internalLink'] = ret.purchaseLink
                }
              }
            }
          } catch (err) {
            console.error(err);
          }
        }

        if (
          //For Listing Product
          myObjStr["searchedProducts"].productDetails &&
          myObjStr["searchedProducts"].productDetails.length > 0
        ) {
          try {
            for (let product of myObjStr["searchedProducts"].productDetails) {
              product.partnerTab = false;
              if (product.bcPID && product.bcPID > 0) {
                let pidArray = [];

                if (partnerCheckoutPids) {
                  // Check if there is a comma (i.e., multiple values), otherwise treat it as a single value
                  pidArray = partnerCheckoutPids.includes(',')
                    ? partnerCheckoutPids.split(',').map(Number)  // Split into array and convert to numbers
                    : [Number(partnerCheckoutPids)];  // Single value, so convert it to an array with one number
                } else {
                  partnerCheckoutPids = sessionStorage.getItem('pids');
                }

                // Now check if the bcPID exists in the array
                if (nvPartnerCheckout && pidArray.indexOf(product.bcPID) !== -1) {
                  // Update the flag (assuming flag is a boolean or some property to mark)
                  product.partnerTab = true;  // You can replace 'flag' with the actual property you want to update
                }
              }
              if(product.locale === "US" &&  product.bcPID && product.bcPID>0
                 && product["retailers"] && product["retailers"].length>0 ){
                    product.isPDP = true;
                    let tmpRetailers = [];
                    for (let [i, ret] of product["retailers"].entries()) {
                      if(ret.partnerId==='111' && ret.purchaseLink.indexOf("store.nvidia.com")!=-1){
                        tmpRetailers[0]=ret;
                      }
                    }
                    if(tmpRetailers.length>0) {
                      product['retailers']=tmpRetailers;
                    }
               } else {
                    product.isPDP = false;
                    if ((product.prdStatus == "buy_now" || product.prdStatus == "out_of_stock") &&
                      !product.digitialRiverID && product.category !== "GFN") {
                      for (let [i, ret] of product["retailers"].entries()) {
                        if (
                          ret.retailerName != null &&
                          ret.retailerName &&
                          ret.retailerName.indexOf("nvidia.com") > -1
                        ) {
                          // if(product["retailers"].length == 1){
                          //   product.isPDP = false;

                          // }
                          product.isPDP = false;
                          // ret.type = 31;
                          let nvRetail = product["retailers"].splice(i, 1)[0];
                          product["retailers"].splice(0, 0, nvRetail);
                        }
                        if (!(product['internalLink'] && product['internalLink'].length)) {
                          product['internalLink'] = ret.purchaseLink
                        }
                      }
                    }

                    if (product.category == "GFN") {
                      for (let [i, ret] of product["retailers"].entries()) {
                        if (ret.retailerName != null && ret.retailerName && ret.retailerName.indexOf("nvidia.com") > -1) {
                          product.isPDP = false;
                          ret.purchaseLink = ret.purchaseLink;
                          let nvRetail = product["retailers"].splice(i, 1)[0];
                          product["retailers"].splice(0, 0, nvRetail);
                        }
                      }
                    }
               } //else

            }
          } catch (err) {
            console.error(err);
          }
        }

        // NVIDIA Retailer Position on Top
        if (
          this.countryCode == "UK" ||
          this.countryCode == "DE" ||
          this.countryCode == "FR" ||
          this.countryCode == "ES" ||
          this.countryCode == "NL" ||
          this.countryCode == "IT"
        ) {
          let femap = {};
          if (this.feSkuInfo) {
            for (var fe of this.feSkuInfo) {
              if (fe.is_active == "true") {
                femap[fe.fe_sku] = fe.product_url;
              }
            }
          }

          if (
            myObjStr["searchedProducts"]["featuredProduct"] &&
            femap[myObjStr["searchedProducts"]["featuredProduct"].productUPC]
          ) {
            let haslink =
              femap[myObjStr["searchedProducts"]["featuredProduct"].productUPC];
            if (haslink) {
              if (
                myObjStr["searchedProducts"]["featuredProduct"]["retailers"] &&
                myObjStr["searchedProducts"]["featuredProduct"]["retailers"]
                  .length > 0
              ) {
                myObjStr["searchedProducts"]["featuredProduct"].prdStatus =
                  "buy_now";
                myObjStr["searchedProducts"]["featuredProduct"][
                  "retailers"
                ][0].directPurchaseLink = haslink;
                myObjStr["searchedProducts"]["featuredProduct"][
                  "retailers"
                ][0].purchaseLink = haslink;
                myObjStr["searchedProducts"]["featuredProduct"][
                  "retailers"
                ][0].type = 29;
              } else {
              }
            } else {
              myObjStr["searchedProducts"]["featuredProduct"].prdStatus =
                "out_of_stock";
              try {
                if (
                  myObjStr["searchedProducts"]["featuredProduct"][
                  "retailers"
                  ] &&
                  myObjStr["searchedProducts"]["featuredProduct"]["retailers"]
                    .length > 0
                ) {
                  myObjStr["searchedProducts"]["featuredProduct"][
                    "retailers"
                  ][0].directPurchaseLink = haslink;
                  myObjStr["searchedProducts"]["featuredProduct"][
                    "retailers"
                  ][0].purchaseLink = haslink;
                  myObjStr["searchedProducts"]["featuredProduct"][
                    "retailers"
                  ][0].type = 80;
                }
              } catch (err) {
                console.log(err);
              }
            }
          }
          if (myObjStr["searchedProducts"].productDetails) {
            for (let pd of myObjStr["searchedProducts"].productDetails) {
              if (pd.isFounderEdition) {
               if(pd["productTitle"].indexOf("<br>")>-1 && pd["productTitle"].split("<br>").length>1){
                  pd["fe_offer"]=pd["productTitle"].split("<br>")[1];
                  pd["productTitle"]=pd["productTitle"].split("<br>")[0];
                }
                let haslink = femap[pd.productUPC];
                if (haslink) {
                  pd.prdStatus = "buy_now";
                  if (pd["retailers"] && pd["retailers"].length > 0) {
                    pd["retailers"][0].directPurchaseLink = haslink;
                    pd["retailers"][0].purchaseLink = haslink;
                    pd["retailers"][0].type = 29;
                  }
                } else {
                  pd.prdStatus =  pd.prdStatus=="upcoming" ?pd.prdStatus :  "out_of_stock";
                  try {
                    if (pd["retailers"] && pd["retailers"].length > 0) {
                      pd["retailers"][0].directPurchaseLink = haslink;
                      pd["retailers"][0].purchaseLink = haslink;
                      pd["retailers"][0].type = 80;
                    }
                  } catch (err) {
                    console.log(err);
                  }
                }
              }
            }
          }
        }

        response = myObjStr;
        return response;
      })
    );
  }

  getPDPLink(productDetail) {
    let l = "";

    if (productDetail.retailers.length > 0) {
      let foundLink = false;
      productDetail.retailers.forEach((element) => {
        if (element.purchaseLink && element.partnerId === "111") {
          let l_parts = element.purchaseLink.split("/");
          l = "/" + l_parts.slice(3).join("/");
          foundLink = true;
          //  console.log("-----l----->",l);
        }
      });
      if (foundLink) {
      return l;
      }
    }


    return (
        "/" +
        $("html").attr("lang") +
        "/consumer/product/" +
        productDetail.productSKU.toLowerCase() +
        "/" +
        productDetail.productTitle
          .replace(productDetail.productSKU, " ")
          .replaceAll("+", "")
          .replaceAll(".", "-")
          .replaceAll(" ", "-")
          .replaceAll("/", "-")
          .toLowerCase()
          .replace(/-+/gi, "-") +
        "/"
      );

  }
  async getProductAsync(queryParams, forceRefresh?: boolean) {
    console.log("DD - resp -1");
    let params = new HttpParams();
    for (let i in queryParams) {
      if (queryParams[i]) {
        params = params.append(i, queryParams[i]);
      }
    }

    let requestOptions = { params: null };
    requestOptions.params = params;

    let api_url = this.currentEnvironment + "/edge/product/search";
    if (this.currentEnvironment == "https://stg-api.nvidia.partners") {
      api_url = this.currentEnvironment + "/zwxrnyowbw/product/search";
    } else if (this.currentEnvironment == "https://dev-api.nvidia.partners") {
      api_url = this.currentEnvironment + "/zupdev/product/search";
    }

    return await this.http.get(api_url, requestOptions).pipe(
      map((response: Response) => {
        const myObjStrOrg = response;
        let myObjStr = myObjStrOrg;
        console.log("feSkuInfo " + this.countryCode);
        console.log(this.feSkuInfo);
        //    this.getFeDetailsAsync().then(value=>{
        if (
          this.countryCode == "UK" ||
          this.countryCode == "DE" ||
          this.countryCode == "FR" ||
          this.countryCode == "ES" ||
          this.countryCode == "NL" ||
          this.countryCode == "IT"
        ) {
          let femap = {};
          if (this.feSkuInfo) {
            for (var fe of this.feSkuInfo) {
              if (fe.is_active == "true") {
                femap[fe.fe_sku] = fe.product_url;
              }
            }
          }
          console.log("prepared the femap for validation");
          console.log(femap);
          if (myObjStr["searchedProducts"]["featuredProduct"]) {
            if (
              femap[myObjStr["searchedProducts"]["featuredProduct"].productUPC]
            ) {
              let haslink =
                femap[
                myObjStr["searchedProducts"]["featuredProduct"].productUPC
                ];
              if (haslink) {
                if (
                  myObjStr["searchedProducts"]["featuredProduct"][
                  "retailers"
                  ] &&
                  myObjStr["searchedProducts"]["featuredProduct"]["retailers"]
                    .length > 0
                ) {
                  myObjStr["searchedProducts"]["featuredProduct"].prdStatus =
                    "buy_now";
                  myObjStr["searchedProducts"]["featuredProduct"][
                    "retailers"
                  ][0].directPurchaseLink = haslink;
                  myObjStr["searchedProducts"]["featuredProduct"][
                    "retailers"
                  ][0].purchaseLink = haslink;
                  myObjStr["searchedProducts"]["featuredProduct"][
                    "retailers"
                  ][0].type = 29;
                } else {
                  myObjStr["searchedProducts"]["featuredProduct"].prdStatus =
                    "out_of_stock";
                }
              } else {
                myObjStr["searchedProducts"]["featuredProduct"].prdStatus =
                  "out_of_stock";
                try {
                  if (
                    myObjStr["searchedProducts"]["featuredProduct"][
                    "retailers"
                    ] &&
                    myObjStr["searchedProducts"]["featuredProduct"]["retailers"]
                      .length > 0
                  ) {
                    myObjStr["searchedProducts"]["featuredProduct"][
                      "retailers"
                    ][0].directPurchaseLink = haslink;
                    myObjStr["searchedProducts"]["featuredProduct"][
                      "retailers"
                    ][0].purchaseLink = haslink;
                    myObjStr["searchedProducts"]["featuredProduct"][
                      "retailers"
                    ][0].type = 80;
                  }
                } catch (err) {
                  console.log(err);
                }
              }
            } else {
              myObjStr["searchedProducts"]["featuredProduct"].prdStatus =
                "out_of_stock";
              myObjStr["searchedProducts"]["featuredProduct"]["retailers"] = [];
            }
          }
          //
          console.log("founder featured sku validation is passed");
          if (myObjStr["searchedProducts"].productDetails) {
            for (let pd of myObjStr["searchedProducts"].productDetails) {
              if (pd.isFounderEdition) {
                let haslink = femap[pd.productUPC];
                if (haslink) {
                  pd.prdStatus = "buy_now";
                  if (pd["retailers"] && pd["retailers"].length > 0) {
                    pd["retailers"][0].directPurchaseLink = haslink;
                    pd["retailers"][0].purchaseLink = haslink;
                    pd["retailers"][0].type = 29;
                  }
                } else {
                  pd.prdStatus = "out_of_stock";
                  try {
                    if (pd["retailers"] && pd["retailers"].length > 0) {
                      pd["retailers"][0].directPurchaseLink = haslink;
                      pd["retailers"][0].purchaseLink = haslink;
                      pd["retailers"][0].type = 80;
                    }
                  } catch (err) {
                    console.log(err);
                  }
                }
              }
            }
          }
        }
        console.log("updated-----------------");
        console.log(myObjStr);
        response = myObjStr;
        return response;
      })
    );
    // }).delayWhen;
  }

  getRetailInfo(upcCode) {
    return this.http
      .get(this.currentEnvironment + `/edge/product/${upcCode}/retailer/info`)
      .pipe(map((res) => res));
  }

  getHeaderData(locale) {
    let region = locale.substr(3);
    let reformattedLocale = locale.substring(0, 2) + "_" + region.toUpperCase();
    console.info("Region", region, "reformattedLocale", reformattedLocale);
    //let borrowShieldHeaderData = false; //(/localhost|wwwdev/).test(window.location.href))
    return this.http.get(
      "https://www.nvidia.com" +
      `/bin/service/nvHeaderFooterSrvc?locale=${reformattedLocale}&region=${region}&brand=geforce`
    );
  }

  getFeDetails_test() {
    // this api call will be enable for UK, FR , DE, ES, NL
    console.log("fe call");

    const feParams: URLSearchParams = new URLSearchParams();
    feParams.set(
      "skus",
      this.countryCode +
      "~NVGFT070~NVGFT080~NVGFT090~NVLKR30S~NSHRMT01~NVGFT060T~187"
    );
    feParams.set("locale", this.countryCode);
    let feRequestOptions = { params: null };
    feRequestOptions.params = feParams;

    return this.http
      .get(
        this.feAPIs[this.origin] + `/partner/v1/feinventory-dep`,
        feRequestOptions
      )
      .pipe(
        map((data) => {
          let feresp = data;
          this.feSkuInfo = feresp["listMap"];
          console.log(this.feSkuInfo);
          return feresp["listMap"];
        })
      );
  }
  async getFeDetailsAsync_dep() {
    // this api call will be enable for UK, FR , DE, ES, NL
    console.log("fe call");
    var feHeaders = new Headers();
    feHeaders.append("Content-Type", "application/json");
    feHeaders.append("Access-Control-Allow-Origin", "*");
    console.log(feHeaders.get("Content-Type"));
    const feParams: URLSearchParams = new URLSearchParams();
    feParams.set(
      "skus",
      this.countryCode +
      "~NVGFT070~NVGFT080~NVGFT090~NVLKR30S~NSHRMT01~NVGFT060T~208"
    );
    feParams.set("locale", this.countryCode);
    let feRequestOptions = { params: null };
    feRequestOptions.params = feParams;
    //feRequestOptions.headers = headers;
    return await this.http
      .get(
        this.feAPIs[this.origin] + `/partner/v1/feinventory-dep`,
        feRequestOptions
      )
      .subscribe((data) => {
        let feresp = data;
        this.feSkuInfo = feresp["listMap"];
        console.log(this.feSkuInfo);
        return feresp["listMap"];
      });
  }
  setFedata(data) {
    this.feSkuInfo = data;
  }
  getFeData() {
    return this.feSkuInfo;
  }
  currentEnv() {
    return this.currentEnvironment;
  }
}
